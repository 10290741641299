import React from 'react';
import PropTypes from 'prop-types';

import { Table, Thead, Tbody } from './styles';

export default function DefaultTable({ head, body }) {
  return (
    <Table>
      <Thead>
        <tr>
          {head.map(content => (
            <th key={content}>{content}</th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {body.map(row => (
          <tr key={Math.random()}>
            {row.map(column => (
              <td key={Math.random()}>{column}</td>
            ))}
          </tr>
        ))}
      </Tbody>
    </Table>
  );
}

DefaultTable.propTypes = {
  head: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.arrayOf(PropTypes.array),
};

DefaultTable.defaultProps = {
  head: ['head', 'head', 'head'],
  body: [['linha', 'linha', 'linha']],
};
