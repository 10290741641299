import styled from 'styled-components';

import { openInput } from 'styles/global';

export const Container = styled.div`
  width: 100%;
  height: 80%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 2%;

  width: 100%;
  height: 10%;
`;

export const Toolbar = styled.div`
  display: flex;
  grid-column-gap:10px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 1%;

  div {
    &.right-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 20%;
      div {
        display: flex;
        justify-content: space-evenly;
        &.right-bar-buttons {
          display: flex;
          justify-content: flex-start;
          width: 60%;
          button {
            width: auto;
            & + button {
              margin-left: 5%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 15% !important;
  height: 100%;

  padding-top: 4%;
  padding-bottom: 4%;
  input {
    width: 0%;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 16px;
    border: 2px solid ${props => props.theme.grafit.default};
    border-left: 0;
    border-right: 0;
    border-top: 0;

    &.show {
      animation: ${openInput} forwards 500ms;
    }
  }

  button {
    background: transparent;

    svg {
      font-size: 40px;
      color: ${props => props.theme.grafit.default};

      &:hover {
        color: ${props => props.theme.grafit.hover};
        transition: color 0.2s ease;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ContainerPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  height: 10%;
  margin-top: 50px;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    width: 30%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      width: 30px;
      height: 30px;

      color: ${props => props.theme.grafit.hover};
      font-size: 16px;
      transition: 200ms ease;
      border-radius: 5px;
    }

    & > li + li {
      margin-left: 7%;
    }

    a.previous_page {
      padding: 5px;
      color: ${props => props.theme.grafit.hover};
      border: 1px solid ${props => props.theme.grafit.default};
    }
    a.next_page {
      padding: 5px;
      color: ${props => props.theme.grafit.hover};
      border: 1px solid ${props => props.theme.grafit.default};
    }

    li.active {
      a {
        color: ${props => props.theme.white.default};
        background: ${props => props.theme.info.default};
        border-radius: 50%;
      }
    }

    a:hover {
      color: ${props => props.theme.info.default};
      transform: scale(1.1);
    }
  }
`;

