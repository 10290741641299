import React from 'react';

import { Link } from 'react-router-dom';

import { AiOutlineContacts, AiOutlineHome, AiOutlineFileText, AiOutlineForm } from 'react-icons/ai';

import { MdSchool } from 'react-icons/md';

import ReactTooltip from 'react-tooltip';

import { Container, SideNav, Avatar, HomeIcon } from './styles';

import placeholder from 'assets/sidebarIcons/placeholder.svg';

export default function Sidebar() {
  function checkActive(pathname) {
    let active = null;

    const [, choosenPath] = window.location.pathname.split('/');

    if (choosenPath === pathname) {
      active = 'active';
    }

    return active;
  }

  return (
    <>
      <Container>
        <Avatar src={placeholder} alt="List clients" />
        <SideNav>
          <li className={checkActive('clients')}>
            <Link to="/clients" data-tip="Clientes">
              <AiOutlineContacts type="idcard" />
            </Link>
          </li>
          <li className={checkActive('clientsPfg')}>
            <Link to="/clientsPfg" data-tip="Clientes PFG's">
              <MdSchool />
            </Link>
          </li>
          <li className={checkActive('reports')}>
            <Link to="/reports" data-tip="Relatórios">
              <AiOutlineFileText />
            </Link>
          </li>
          <li className={checkActive('strategy')}>
            <Link to="/strategy" data-tip="Strategy">
              <AiOutlineForm />
            </Link>
          </li>
        </SideNav>
        <HomeIcon className={checkActive('home')}>
          <Link to="/home">
            <AiOutlineHome type="home" />
          </Link>
        </HomeIcon>
      </Container>
      <ReactTooltip />
    </>
  );
}
