const light = {
  white: {
    default: '#fff',
    disabled: '#F1F1FF',
  },
  primary: {
    hover: '#19cc9f',
    default: '#00ffbf',
    disabled: '#ccfff2',
  },
  secondary: {
    hover: '#000033',
    default: '#4545e6',
    disabled: ' #ccccff',
  },
  info: {
    hover: '#236499',
    default: '#3ba7ff',
    disabled: '#bae0ff',
    custom: '#77869E',
  },
  success: {
    hover: '#2a8038',
    default: '#4be364',
    disabled: '#ccffd4',
  },
  warning: {
    hover: '#d9bc2b',
    default: '#f6cd00',
    disabled: '#fffbcc',
  },
  danger: {
    hover: '#ab1136',
    default: '#f7194e',
    disabled: '#ffccd8',
  },
  grafit: {
    hover: '#999999',
    default: '#cccccc',
    disabled: '#f2f2f2',
  },
  gray: {
    hover: '#E2E2E2',
    default: '#8C8B8C',
    disabled: '#f0f1f7',
    custom: '#282728',
  },
  black: {
    default: '#111111',
  },
  receipts: {
    hover: '#999999',
    default: '#4545e6',
    disabled: '#e6e6ff',
    custom: '#9999ff',
  },
  spendings: {
    hover: '#14abcc',
    default: '#13d5ff',
    disabled: '#ccf6ff',
    custom: '#66e4ff',
  },
  debts: {
    hover: '#c4143e',
    default: '#f7194e',
    disabled: '#ffccd8',
    custom: '#ff809e',
  },
  investments: {
    hover: '#999999',
    default: '#4be364',
    disabled: '#ccffd4',
    custom: '#72e384',
  },
  darker: {
    hover: '#000033',
    default: '#1a1a47',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  blueDarker: {
    hover: '#000033',
    default: '#140361',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  layout: {
    layout_light: '#7386FF',
    layout_dark: '#140361',
    default: "#1C11A7",
    disabled: '#5960FF',
    custom: '#4739EB',
  },
  blue: {
    default: "#0C053F",
    disabled: '#0C053F',
    custom: '#0C053F',
    hover: "#1C11A7",
  },
  pgec: {
    default: '#17ba92',
  },
  eventualFlexible: {
    default: '#45edc3', // cor no zeplin -> #8ef4db
  },
};

export default light;
