import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }

`
export const Container = styled.div`
  width: 100%;
  height: 80%;
`;

export const TableContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} .8s ease forwards;
`

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > button + button {
    margin-left: 8%;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;

  div {
    &.right-bar {

      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 30%;
      div {
        display: flex;
        justify-content: space-evenly;
        &.right-bar-buttons {
          display: flex;
          justify-content: flex-start;
          width: 40%;
          button {
            width: auto;
            & + button {
              margin-left: 5%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export const Form = styled.form`
  width: 400px;
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5%;
  div {
    display: flex;
    flex-direction: column;
  }

  &.buttonsForm {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 2%;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  min-width: 300px;
  margin-top: 10px;
  height: 38px;
  font-size: 15px;
  color: #212529;
  padding: 2px 8px;
  border: 1px solid #cccccc;
`;