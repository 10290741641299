import React, { useState } from 'react';

import { AiFillLock } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Wrapper, Container, Form, InputGroup } from './styles';

import Button from 'components/Button';

import api from 'services/api';

export default function ResetPassoword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const url = new URLSearchParams(window.location.search);
  const token = url.get('token');

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      api.put('/forgot_password', {
        token,
        password,
        password_confirmation: confirmPassword,
      });

      toast.success('Senha alterada com sucesso');
    } catch (err) {
      toast.error('Error');
    }
  }

  return (
    <Wrapper>
      <Container>
        <h1>Digite uma nova senha</h1>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="inputPassword">Email:</label>
          <InputGroup>
            <AiFillLock size={25} />
            <input
              type="password"
              name="password"
              id="inputPassword"
              placeholder="Digite sua nova senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </InputGroup>
          <label htmlFor="inputConfirmPassword">Confirme sua senha:</label>
          <InputGroup>
            <AiFillLock size={25} />
            <input
              type="passoword"
              name="password"
              id="inputConfirmPassword"
              placeholder="Confirme sua nova senha"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </InputGroup>

          <Link to="/">Voltar ao Login</Link>

          <Button type="submit" color="primary">
            Enviar
          </Button>
        </Form>
      </Container>
    </Wrapper>
  );
}
