import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// import { AiOutlineBook, AiOutlineCalendar } from 'react-icons/ai';

import { add, startOfDay, addHours } from 'date-fns';

import { toast } from 'react-toastify';

import makeAnimated from 'react-select/animated';

import { ModalContent, Form, DivGroup } from '../styles';

import Modal from 'components/Modal';

import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Input/Dropdown';
import api from 'services/api';

export default function ModalCreate({ setShowModal, propsEvent, setEvents }) {
  // Dados do formulário
  const [name, setName] = useState('');

  const [description, setDescription] = useState('');
  const [typeMeeting, setTypeMeeting] = useState({});

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [where, setWhere] = useState('');
  const [who, setWho] = useState('');
  const [how, setHow] = useState('');

  const [toggleButton] = useState('activities');

  const [optionsDrop, setOptionsDrop] = useState([]);

  // Clientes do planejador vindos da api
  const [clientsOfPlanner, setClientsOfPlanner] = useState([]);

  // Ids dos convidados da reunião
  const [guests, setGuests] = useState([]);

  const { id } = useSelector(state => state.user.profile);

  const animatedComponent = makeAnimated();

  useEffect(() => {
    async function fetchDataClients() {
      const { data } = await api.get(`/planners/${id}/clients`);

      setClientsOfPlanner([
        ...data.data.map(client => ({
          label: client.name,
          value: client.name,
          ...client,
        })),
      ]);
    }

    async function fetchData() {
      const { data } = await api.get('/meeting_types');
      setOptionsDrop(
        data.map(role => ({
          value: role?.name,
          label: role?.name,
          ...role,
        }))
      );
    }
    fetchDataClients();
    fetchData();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (toggleButton === 'activities') {
        const { data } = await api.post(`/users/${id}/activities`, {
          name,
          description,
          start: addHours(new Date(propsEvent?.date), 12),
          end: addHours(new Date(propsEvent?.date), 13),
          where,
          who,
          how,
        });

        setEvents(event => [
          ...event,
          {
            id: data?.id,
            title: data?.description,
            editable: true,
            backgroundColor: 'red',
            color: 'white',
            date_start: new Date(data?.start),
            date_end: new Date(data?.end),
            who: data?.who,
            where: data?.where,
            how: data?.how,
            ...data,
          },
        ]);
      } else {
        const meeting_type_id = typeMeeting?.id;

        const [startHour, startMinute] = start.split(':');

        const [endHour, endMinute] = end.split(':');

        if (startHour > endHour) {
          return toast.warn('O horário inicial deve ser menor que o final');
        }
        const dayStart = startOfDay(new Date(propsEvent?.date));

        const eventStart = add(dayStart, {
          hours: startHour,
          minutes: startMinute,
        });

        const eventEnd = add(dayStart, {
          hours: endHour,
          minutes: endMinute,
        });

        const { data } = await api.post(`/users/${id}/meetings`, {
          meeting_type_id,
          description,
          start: eventStart,
          end: eventEnd,
        });

        const { data: data_guests } = await api.post(
          `/meetings/${meeting_type_id}/guests`,
          {
            users: guests,
          }
        );

        setEvents(event => [
          ...event,
          {
            id: data?.id,
            title: data?.description,
            editable: true,
            backgroundColor: 'red',
            color: 'white',
            date_start: new Date(data?.start),
            date_end: new Date(data?.end),
            ...data,
          },
        ]);

        toast.success('Evento criado com sucesso');
      }

      setName('');
      setDescription('');
      setStart('');
      setEnd('');
      setWhere('');
      setWho('');
      setHow('');
      return setShowModal(false);
    } catch (err) {
      return toast.error('Falha ao criar Evento');
    }
  }

  return (
    <Modal title="Novo Evento" icon={null} color="info">
      <ModalContent>
        {/* <Actions>
          <div
            className={`outside ${toggleButton === 'activities' && 'checked'}`}
          >
            <div className="inside">
              <button onClick={() => setToggleButton('activities')}>
                <AiOutlineBook size={32} />
                <span>Atividade</span>
              </button>
            </div>
          </div>
          <div
            className={`outside ${toggleButton === 'meetings' && 'checked'}`}
          >
            <div className="inside">
              <button onClick={() => setToggleButton('meetings')}>
                <AiOutlineCalendar size={32} />
                <span>Reunião</span>
              </button>
            </div>
          </div>
        </Actions> */}
        <Form onSubmit={handleSubmit}>
          {toggleButton === 'activities' && (
            <Input
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          )}
          {toggleButton === 'meetings' && (
            <>
              <Select
                label="Tipo da reunião"
                options={optionsDrop}
                defaultValue={optionsDrop[0]}
                onChange={selected => setTypeMeeting(selected)}
              />
              <DivGroup>
                <Input
                  type="time"
                  label="Início"
                  width="45%"
                  value={start}
                  onChange={e => setStart(e.target.value)}
                />
                <Input
                  type="time"
                  label="Fim"
                  width="45%"
                  value={end}
                  onChange={e => setEnd(e.target.value)}
                />
              </DivGroup>
            </>
          )}

          <Input
            label="Descrição"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          {toggleButton === 'activities' && (
            <>
              <Input
                label="Onde?"
                value={where}
                onChange={e => setWhere(e.target.value)}
              />
              <Input
                label="Como?"
                value={how}
                onChange={e => setHow(e.target.value)}
              />
              <Input
                label="Quem?"
                value={who}
                onChange={e => setWho(e.target.value)}
              />
            </>
          )}

          {toggleButton === 'meetings' && (
            <Select
              label="Selecione o(s) convidado(s)"
              placeholder="Clientes..."
              closeMenuOnSelect={false}
              components={animatedComponent}
              defaultValue={guests}
              isMulti
              options={clientsOfPlanner}
              onChange={selectedItems => {
                if (!selectedItems) {
                  setGuests([]);
                  return;
                }
                setGuests(selectedItems.map(element => element.id));
              }}
            />
          )}

          <div className="select-buttons">
            <Button color="grafit" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button color="info" type="submit">
              Confirmar
            </Button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
}

ModalCreate.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  propsEvent: PropTypes.shape({}).isRequired,
  setEvents: PropTypes.func.isRequired,
};
