import React from 'react';
import PropTypes from 'prop-types';

import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';

import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Card from 'components/Cards/';

const optionsHeader = {
  left: 'title, prev, next',
  center: '',
  right: 'dayGridMonth, listWeek, dayGridWeek',
};

const buttonText = {
  today: 'Hoje',
  dayGridMonth: 'Mês',
  week: 'week',
  dayGridWeek: 'Semana',
  list: 'list',
};

export default function Calendar({
  showModal,
  setShowModal,
  setPropsEvent,
  events,
  showUpdateModal,
  setShowUpdateModal,
  setSelectEvent,
}) {
  const calendarRef = React.createRef();

  function handleEventClick(data) {
    const event = data.event._def.extendedProps;
    setSelectEvent(event);
  }

  return (
    <Card width="100%" height="940px">
      <FullCalendar
        themeSystem="bootstrap"
        ref={calendarRef}
        defaultView="dayGridMonth"
        titleFormat={{ year: 'numeric', month: 'long' }}
        weekends
        displayEventTime
        editable
        dropAccept
        droppable
        selectable
        events={events}
        dateClick={props => {
          setShowModal(!showModal);
          setPropsEvent(props);
        }}
        eventClick={data => {
          handleEventClick(data);
          setShowUpdateModal(!showUpdateModal);
        }}
        header={optionsHeader}
        buttonText={buttonText}
        locale="pt-br"
        height={880}
        plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
      />
    </Card>
  );
}

Calendar.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setPropsEvent: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showUpdateModal: PropTypes.bool.isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
  setSelectEvent: PropTypes.func.isRequired,
};
