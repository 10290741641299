import { createGlobalStyle, keyframes } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export const openInput = keyframes`
  0% {
    width:0%;
    opacity: 0;
  }
  100%{
    width: 60%;
    opacity: 1;

  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
  }

  html, body, #root {
    height: 100%;
  }
  
  body {
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    font-family: 'Nunito', sans-serif;
  }

  body, input, button {
    font-family: 'Nunito', sans-serif;
  }

  button {
    border: 0;
    cursor: pointer;
  }

  button:focus {
      outline: 0;
  }

  button {
      &::-moz-focus-inner {
        outline: 0 !important;
      }
      border: 0;
      cursor: pointer;
  }

  ::-moz-focus-inner {
      outline: 0 !important;
  }

  a {
    text-decoration: none !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(185, 179, 173, 0.7);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(185, 179, 173);
    transform: scale(2);
  }

  .spin {
    animation: ${spin} 2s infinite linear;
  }
`;

export const colors = {
  primary: {
    hover: ' #19cc9f',
    default: '#00ffbf',
    disabled: '#ccfff2',
  },
  secondary: {
    hover: '#000033',
    default: '#4545e6',
    disabled: ' #ccccff',
  },
  info: {
    hover: '#236499',
    default: '#3ba7ff',
    disabled: '#bae0ff',
  },
  success: {
    hover: '#2a8038',
    default: '#4be364',
    disabled: '#ccffd4',
  },
  warning: {
    hover: '#d9bc2b',
    default: '#f6cd00',
    disabled: '#fffbcc',
  },
  danger: {
    hover: '#ab1136',
    default: '#f7194e',
    disabled: '#ffccd8',
  },
  grafit: {
    hover: '#999999',
    default: '#cccccc',
    disabled: '#f2f2f2',
  },
  white: {
    default: '#ffffff',
  },
  blue: {
    default: "#0C053F",
    disabled: '#0C053F',
    custom: '#0C053F',
  },
  blueDarker: {
    hover: '#000033',
    default: '#140361',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
};
