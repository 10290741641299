import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme[props.color].default};
  height: 100%;
  overflow: hidden;
  svg {
    width: inherit;
    height: inherit;
    /* width: 35%;
    height: 35%; */
  }
`;
