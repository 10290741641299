import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { AiOutlineMore } from 'react-icons/ai';

import { Container } from './styles';

export default function Dropdown({ icon, children }) {
  const [show, setShow] = useState(false);
  return (
    <Container>
      <button
        type="button"
        onClick={() => setShow(true)}
        onBlur={() => setTimeout(() => setShow(false), 100)}
      >
        {icon}
      </button>
      {show && <div className="options">{children}</div>}
    </Container>
  );
}

Dropdown.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

Dropdown.defaultProps = {
  icon: <AiOutlineMore />,
};
