import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 99%;

  td:hover {
    cursor: cell;
  }
  div.fc-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div.fc-header-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div.fc-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  button.fc-prev-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    color: ${props => props.theme.grafit.hover};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-width: 2px;
    border-color: ${props => props.theme.grafit.hover};
  }

  button.fc-next-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    color: ${props => props.theme.grafit.hover};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-width: 2px;
    border-color: ${props => props.theme.grafit.hover};
  }

  button:hover {
    border-color: ${props => props.theme.grafit.hover};
  }
`;

export const NewEvent = styled.div`
  transform: translateY(-50px) translateX(-400px);

  height: 20px;

  button {
    & > svg {
      color: ${props => props.theme.info.default};
    }
  }
`;
