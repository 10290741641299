import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';

import ButtonMui from '@mui/material/Button';

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Loading from 'components/Loading';

import { format } from 'date-fns';

import ReactTooltip from 'react-tooltip';

import Select from 'components/Input/Dropdown';

import Switch from 'components/Input/Switch'

import api, { api_nort } from 'services/api';

import {
  Container,
  Title,
  Toolbar,
  TableContainer
} from './styles';

import debounce from 'utils/debounce';

import Table from 'components/Table/Default';
import Dropdown from 'components/Button/DropdownClose';
import { Link } from '@mui/material';

export default function Reports() {

  const { id } = useSelector(state => state.user.profile);

  const [clientList, setClientList] = useState([{ label: "Carregando lista...", value: null }]);

  const ref = useRef();

  const [activeClient, setActiveClient] = useState(null);

  const [filter, setFilter] = useState(clientList[0]);

  const [isLoading, setIsLoading] = useState(false);

  // Planners da api
  const [tableList, setTableList] = useState([]);

  const [colorList, setColorList] = useState([]);

  const token = useSelector(state => state.auth.token);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // border: '0 !important',
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        // border: '0 !important',
      },
      background: '#f2f2f2',
      color: '#212529',
      // match with the menu
      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      background: '#f2f2f2',
      color: '#212529',
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: '#f2f2f2',
      color: '#212529',
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color: '#212529',
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
  };

  const headTable = [
    'Relatório',
    'Aplica?',
    'Previsão',
    'Dt. Entrega',
    '',
  ];

  const startLoading = () => {

    setIsLoading(true);

    // Apaga os dados da tabela
    setActiveClient(null);

    setTableList([]);

    setColorList([]);

  }

  const uploadFile = async (e, id, name) => {
    ref.current.close();

    const file = e.target.files[0];

    if (!file) {
      return toast.error('Selecione um arquivo');
    }

    startLoading();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`report/${id}`, formData);

      if (response.data.id) {
        toast.success('Arquivo enviado com sucesso!');
        fetchClientsData();
      } else {
        throw new Error('Falha ao enviar o arquivo. Tente novamente mais tarde.');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const mountDatePicker = (item) => {
    const { titulo, previsao } = item;

    if (titulo !== "ORÇAMENTÁRIO" && titulo !== "PATRIMONIAL") return '-';

    if (previsao === null) return "-";

    return <DesktopDatePicker
      inputFormat="dd/MM/yyyy"
      value={previsao}
      readOnly
      renderInput={(params) => (
        <TextField color='primary'
          variant="filled"
          margin='none'
          sx={{
            svg: { color: '#fff' },
            input: { color: '#fff', padding: 0 },
            label: { color: '#fff' }
          }}
          {...params} />
      )}
    />
  }

  useEffect(() => {
    if (activeClient?.relatorios) {
      const resultadoLista = activeClient.relatorios.map(relatorio => {
        return {
          id: relatorio.id_relatorio,
          titulo: relatorio.tipo,
          aplica: relatorio.aplica,
          previsao: relatorio.data_previsao,
          dataEntrega: (relatorio.data_entrega === null ? "-" : format(new Date(relatorio.data_entrega), 'dd/MM/yyyy')),
          color: getColor(relatorio.tipo, relatorio.data_entrega, relatorio.data_diferenca),
          linkUrlReport: relatorio.relatorio_url,
          colorBlue: getColorBlue(relatorio.tipo, relatorio.data_entrega, relatorio.aplica)
        }
      });

      const resultadoCores = resultadoLista.map(relatorio => {
        if (relatorio.colorBlue !== null) return relatorio.colorBlue
        return relatorio.color
      });
      setColorList(resultadoCores)

      setTableList(
        resultadoLista.map((item) => {
          return [
            `${item.titulo}`,
            <Switch
              key={item.id}
              isOn={item.aplica}
              onColor="#4545e6"
              handleToggle={() => updateToggle(item.id, item.aplica)}
            />,
            mountDatePicker(item)
            ,
            item.linkUrlReport !== null ? <Link href={item.linkUrlReport} underline="hover" target="_blank">
              {String(item.dataEntrega)}
            </Link> : `${String(item.dataEntrega)}`,
            <div className="div-icons">
              <div className="icons">
                <Dropdown ref={ref} canClose={false} >
                  <ButtonMui variant="contained" component="label">
                    Upload
                    <input hidden onChange={(e) => {
                      uploadFile(e, item.id, "Relatorio" + item.titulo + '-' + filter.label);
                    }} accept=".pdf" type="file" />
                  </ButtonMui>
                </Dropdown>
              </div>
            </div>,
          ]
        }));
    }
    // eslint-disable-next-line
  }, [activeClient])

  // Load initial clients
  useEffect(() => {
    async function listCLients() {
      //const response = await api_nort.get('/Usuario/get/client');
      const response = await api_nort.get(`Usuario/${id}/clienteplanner`);

      var resultsConverted = response.data.map((cliente) => {
        return {
          label: cliente.nome,
          value: cliente.id
        }
      })

      resultsConverted.unshift({
        label: "Selecione o cliente",
        value: 0
      })

      setFilter(resultsConverted[0])
      setClientList(resultsConverted);
    }
    listCLients();
    // eslint-disable-next-line
  }, []);

  const fetchClientsData = async () => {
    if (filter.value === 0 || filter.value === null) return;
    startLoading();

    const response = await api_nort.get(`Usuario/${filter.value}/relatorios`);


    setActiveClient(response.data);

    setIsLoading(false);
  }

  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filter])

  const getColor = (tipo, dataEntrega, value) => {
    if (tipo === "ORÇAMENTÁRIO" || tipo === "PATRIMONIAL") {
      var valor = Number(value);

      if (dataEntrega !== null) return "blue"
      if (value === null) return null
      if (valor <= 0) return "red"
      if (valor >= 30) return "green"
      if (valor < 30) return "yellow"
    }
  }

  //Caso o relatório possua Dt. Entrega e NÃO possua Previsão, a cor da linha deverá ficar VERDE
  const getColorBlue = (tipo, dataEntrega, aplica) => {
    if (tipo === "ORÇAMENTÁRIO" || tipo === "PATRIMONIAL") return null;
    if (dataEntrega !== null) return "blue"
    if (aplica) return "yellow"
    return null
  }

  const updateToggle = (id, aplica) => {

    startLoading();

    async function listCLients() {
      const response = await api_nort.patch(`Usuario/${id}/aplica`, { aplica: !aplica });

      fetchClientsData();

      if (response.data.sucesso) {
        toast.success('Dado atualizado!');
      } else {
        toast.error('Erro ao tentar atualizar o dado');
      }
    }

    listCLients();
  }

  return (
    <>
      <Container>
        <Toolbar>
          <Select
            value={filter}
            options={clientList}
            onChange={selected => setFilter(selected)}
            styles={customStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,

              colors: {
                neutral50: '#fff',
                primary: '#fff',
              },
            })}
          />
          <Title>
            Contrato: {activeClient !== null ? activeClient.contrato : "--"}
          </Title>
          <div className="right-bar">
            <div className="right-bar-buttons">
            </div>
          </div>
        </Toolbar>
        {isLoading &&
          <Loading />
        }
        {tableList.length > 0 &&
          <TableContainer>
            <Table head={headTable} body={tableList} colorList={colorList} />
          </TableContainer>
        }
      </Container>
      <ReactTooltip />
    </>
  );
}
