import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 5%;
  width: 100%;

  div.checked {
    background: ${props => props.theme.info.disabled} !important;

    button {
      svg {
        color: ${props => props.theme.info.default};
      }
      span {
        color: ${props => props.theme.info.default};
      }
    }
  }

  div.outside {
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.grafit.disabled};
    border-radius: 50%;
    transition: 200ms ease;
    width: 85px;
    height: 85px;

    &:hover {
      background: ${props => props.theme.info.disabled} !important;

      button {
        svg {
          color: ${props => props.theme.info.default};
        }
        span {
          color: ${props => props.theme.info.default};
        }
      }
    }

    div.inside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        background: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export const Form = styled.form`
  div.select-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-top: 5%;
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    background: pink;

    border-radius: 50%;

    &.checked {
      background: ${props => props.theme.success.disabled};

      button {
        span {
          color: ${props => props.theme.success.default};
        }
        svg {
          color: ${props => props.theme.success.default};
        }
      }
    }

    &.unchecked {
      background: ${props => props.theme.danger.disabled};

      button {
        span {
          color: ${props => props.theme.danger.default};
        }
        svg {
          color: ${props => props.theme.danger.default};
        }
      }
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: none;

      span {
        font-size: 12px;
      }
    }
  }
`;
