import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border: none;
  border-spacing: 0 10px;

  border-collapse: separate;
`;

export const Thead = styled.thead`
  color: ${props => props.theme.grafit.hover};
  font-size: 17px;

  th {
    padding: 20px;
    font-weight: normal;
  }

  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Tbody = styled.tbody`
  color: #999999;
  font-size: 17px;

  tr {
    transition: 500ms ease;
  }

  td {
    padding: 25px;
    transition: 500ms ease;
    background: #fff;
  }

  td:last-child {
    width: 1%;
    padding: 0;
    padding-right: 10px;
    text-align: right;
    font-size: 30px;

    svg {
      cursor: pointer;
      transition: 200ms ease;
      margin-top: 15px;
      font-size: 25px;
    }

    svg:hover {
      color: ${props => props.theme.info.default};
      transform: scale(1.2);
    }
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
