import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineHome } from 'react-icons/ai';

import { Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';

import { Container, Wrapper, BreadCrumb } from './styles';

import routesInfo from 'routes/routesInfo';

export default function DashboardLayout({ children }) {
  const [, choosenPath, choosenSubPath] = window.location.pathname.split('/');

  const pageInfo = routesInfo[choosenPath];

  const [subPageInfo] = pageInfo.subPages.filter(
    subPage => subPage.path === choosenSubPath
  );

  return (
    <Container>
      <Header />
      <Sidebar />
      <Wrapper>
        <BreadCrumb>
          {!(pageInfo.path === 'home') && (
            <Link to="/home">
              <AiOutlineHome />
              <span> / </span>
            </Link>
          )}
          <Link
            className={`main ${pageInfo.subPages.length ? '' : 'active'}`}
            to={`/${pageInfo.path}/${pageInfo.subPages[0]?.path}`}
          >
            {pageInfo.title}
          </Link>
          {subPageInfo && (
            <>
              <span> / </span>
              <Link
                className="active"
                to={`/${pageInfo.path}/${subPageInfo.path}`}
              >
                {' '}
                {subPageInfo.title}
              </Link>
            </>
          )}
        </BreadCrumb>
        {children}
      </Wrapper>
    </Container>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
