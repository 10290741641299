import React, { useState, useEffect, useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { useSelector } from 'react-redux';

import { Container } from './styles';

import FullCalendar from './FullCal';

import Modal from './Modals/ModalCreate';
import UpdateModal from './Modals/ModalUpdate';

import api from 'services/api';

export default function Calendar() {
  const [activities, setActivities] = useState([]);

  const [selectedEvent, setSelectEvent] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [propsEvent, setPropsEvent] = useState({});

  const { id } = useSelector(state => state.user.profile);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    async function fetchActivities() {
      const { data: meetings } = await api.get(`/users/${id}/meetings`);
      const { data } = await api.get(`/users/${id}/activities`);

      const dataMeetings = meetings.map(meet => {
        let backgroundColor = theme.receipts.custom;

        if (meet.done) {
          backgroundColor = theme.success.disabled;
        }

        if (new Date(meet.start) < new Date() && !meet.done) {
          backgroundColor = theme.danger.default;
        }

        return {
          id: meet?.id,
          _id: meet?.id,
          title: meet?.name,
          editable: true,
          backgroundColor,
          color: 'white',
          date_start: new Date(meet?.start),
          date_end: new Date(meet?.end),
          ...meet,
        };
      });

      const dataActivities = data.map(act => {
        let backgroundColor = theme.receipts.custom;

        if (act.done) {
          backgroundColor = theme.success.disabled;
        }

        if (new Date(act.start) < new Date() && !act.done) {
          backgroundColor = theme.danger.default;
        }

        return {
          id: act?.id,
          _id: act?.id,
          title: act?.name,
          editable: true,
          backgroundColor,
          color: 'white',
          date_start: new Date(act?.start),
          date_end: new Date(act?.end),
          who: act?.who,
          where: act?.where,
          how: act?.how,
          ...act,
        };
      });

      const events = [dataMeetings, dataActivities].flat();

      setActivities(events);
    }
    fetchActivities();
  }, [id, theme.danger.default, theme.receipts.custom, theme.success.disabled]);

  return (
    <>
      <Container>
        <FullCalendar
          events={activities}
          showModal={showModal}
          setShowModal={setShowModal}
          setPropsEvent={setPropsEvent}
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          setSelectEvent={setSelectEvent}
        />
      </Container>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          propsEvent={propsEvent}
          setEvents={setActivities}
        />
      )}
      {showUpdateModal && !selectedEvent.meeting_type && (
        <UpdateModal
          setShowUpdateModal={setShowUpdateModal}
          selectedEvent={selectedEvent}
          setEvents={setActivities}
        />
      )}
    </>
  );
}
