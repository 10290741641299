import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import history from 'services/history';

import { signInSuccess, signFailure } from './actions';

import api, { api_nort } from 'services/api';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api_nort.post, `Auth/session`, { email, password });

    const { token, user, roles, permissions } = response.data;

    if (user.type === 'client') {
      toast.error('Acesso negado');
      yield put(signFailure());
      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;
    api_nort.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess({ token, user, roles, permissions }));

    history.push('/home');
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api_nort.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
