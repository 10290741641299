import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: auto;

  > button.active {
    transform: translateY(-5px) scale(1.2);
    margin: 0 20px;
    transition: 200ms ease-out;
  }

  > button + button {
    margin-left: 10px;
  }
`;
