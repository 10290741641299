import React from 'react';
import './config/ReactotronConfig';

import { Provider } from 'react-redux';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { PersistGate } from 'redux-persist/integration/react';

import { LastLocationProvider } from 'react-router-last-location';

import { ThemeProvider } from 'styled-components';

import { Router } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import history from 'services/history';

import light from 'styles/themes/light';

import GlobalStyle from 'styles/global';

import Routes from 'routes';

import { store, persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={light}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router history={history}>
              <LastLocationProvider>
                <ToastContainer />
                <GlobalStyle />
                <Routes />
              </LastLocationProvider>
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
