import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const ContainerPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 10%;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    width: 30%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      width: 30px;
      height: 30px;

      color: ${({ theme }) => theme.grafit.hover};
      font-size: 16px;
      transition: 200ms ease;
      border-radius: 5px;
    }

    & > li + li {
      margin-left: 7%;
    }

    a.previous_page {
      padding: 5px;
      color: ${({ theme }) => theme.grafit.hover};
      border: 1px solid ${({ theme }) => theme.grafit.default};
    }
    a.next_page {
      padding: 5px;
      color: ${({ theme }) => theme.grafit.hover};
      border: 1px solid ${({ theme }) => theme.grafit.default};
    }

    li.active {
      a {
        color: ${({ theme }) => theme.white.default};
        background: ${({ theme }) => theme.info.default};
        border-radius: 50%;
      }
    }

    a:hover {
      color: ${({ theme }) => theme.info.default};
      transform: scale(1.1);
      cursor: pointer;
    }
  }
`;
