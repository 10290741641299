import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import {
  AiOutlineSearch,
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from 'react-icons/ai';

import ReactPaginate from 'react-paginate';

import { Container, DivInput, ContainerPaginate, Toolbar,} from './styles';

import Table from 'components/Tables/Default';
import Dropdown from 'components/Button/Dropdown';
import Select from 'components/Input/Dropdown';

import { api_nort } from 'services/api';
import Loading from 'components/Loading';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Modal from 'components/Modal/BlankModal';
import Button from 'components/Button/ModalButton';
import { DivButtons } from 'components/Button/ModalButton/styles';

export default function Customers() {
  const [customers, setCustomers] = useState([]);

  // Mostra input de pesquisa
  const [showInput, setShowInput] = useState(false);

  // Mostra modal de data para última visita
  const [showDateLastVisitModal, setshowDateLastVisitModal] = useState(false);

  // Data última visita no cliente
  const [newDateLastVisit, setnewDateLastVisit] = useState('');

  // Usuário selecionado para exclusão ou edição
  const [selectedUser, setSelectedUser] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const { id } = useSelector(state => state.user.profile);
  const token = useSelector(state => state.auth.token);
  const loggedUser = useSelector(state => state.user.profile);
  const head = ['Nome', 'Dt. última visita', 'ID', 'Patrimônio', 'Data de criação', ''];
  const inputRef = useRef();

  const AplicaFiltroAtivoInativo = [
    {label: "Ativo", value: 0},
    {label: "Inativo", value: 1},
    {label: "Todos", value: 2},
  ]
  const [filterAtivoInativo, setfilterAtivoInativo] = useState(AplicaFiltroAtivoInativo[0]);
  const [searchInputPlanner, setsearchInputPlanner] = useState('');

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // border: '0 !important',
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        // border: '0 !important',
      },
      background: '#f2f2f2',
      color: '#212529',
      // match with the menu
      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      background: '#f2f2f2',
      color: '#212529',
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: '#f2f2f2',
      color: '#212529',
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color: '#212529',
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
  };

  useEffect(() => {
    fetchClientsData();
    // eslint-disable-next-line
  }, [id, page, searchInputPlanner, filterAtivoInativo]);
  
  const fetchClientsData = async() => {
      var search = searchInputPlanner === '' ? 'undefined' : searchInputPlanner;
      const response = await api_nort.get(`/Usuario/${id}/${page}/${filterAtivoInativo.value}/${search}`);
      if(response.status === 200) {
        const { data } = response;
          setPageCount(data.total/5);
          const resultadoLista = data.data.map(customer => {
            return {
              nomeCliente: customer.nome,
              ultimaVisita: customer.data_visita === null ? "-" : format(new Date(customer.data_visita), 'dd/MM/yyyy'),
              id: customer.id,
              dataCriacao: format(new Date(customer.data_criacao), 'dd/MM/yyyy')
            }
          });
  
          setCustomers(
            resultadoLista.map(item => [
              item.nomeCliente,
              item.ultimaVisita,
              item.id,
              'Patrimônio',
              item.dataCriacao,
              <Dropdown>
                <button
                  onMouseDown={() => {setshowDateLastVisitModal(true); setSelectedUser(item);}}
                >
                  Dt. última visita
                </button>
                <button
                  onMouseDown={() => {
                    window.open(
                      `${process.env.REACT_APP_CLIENT_URL ||
                        'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                        item.id
                      }&external_user_id=${loggedUser.id}`
                    );
                  }}
                >
                  Acessar a Yuno On
                </button>
              </Dropdown>,
          ])
        );
      }
  }

  //CONTROLADOR Dt. Ultima visita
  async function handleDateLastVisitModal() {
    let isValidDate = Date.parse(newDateLastVisit)
    if (isNaN(isValidDate)) {
      toast.error('Data de última visita é inválida')
      return
    }
    if(new Date(newDateLastVisit) < new Date('1900-01-01')){
      toast.error('Data de última visita é inválida')
      return
    }
    setIsLoading(true);

    try {
      await api_nort.post(`/usuario/visita`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        data_visita: newDateLastVisit,
        id_planejador: loggedUser.id,
        id_cliente: selectedUser.id,
      });

      await fetchClientsData();

      toast.success('ùltimo dia de visita adicionado com sucesso');
      setIsLoading(false);

    } catch (err) {
      toast.error(
        'Falha ao adicionar o último dia da visita'
      );
      setIsLoading(false);
    }
  }
  function teste(e){
    setsearchInputPlanner(e)
    setPage(0);
  }
  return (
    <>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <Container>
          <Toolbar>
              <Select
                label={'Status do cliente'}
                value={filterAtivoInativo}
                options={AplicaFiltroAtivoInativo}
                onChange={selected => setfilterAtivoInativo(selected)}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
  
                  colors: {
                    neutral50: '#fff',
                    primary: '#fff',
                  },
                })}
              />
              <DivInput>
                <button
                  onClick={() => {
                    setShowInput(!showInput);
                    inputRef.current.focus();
                  }}
                  type="submit"
                >
                  <AiOutlineSearch />
                </button>
                <input
                  className={showInput ? 'show' : ''}
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchInputPlanner}
                  ref={inputRef}
                  onChange={e => teste(e.target.value)}
                />
              </DivInput>
          </Toolbar>
          <Table head={head} body={customers} />
          {showDateLastVisitModal && (
              <Modal color="grafit" title="Data da última visita">
                  <DesktopDatePicker
                    label="Informe a data"
                    inputFormat="dd/MM/yyyy"
                    value={newDateLastVisit}
                    onChange={(e) => setnewDateLastVisit(e)}
                    renderInput={(params) => <TextField color='primary' 
                                                  variant="filled" 
                                                  margin='none'
                                                  sx={{
                                                    svg: {color: '#fff'},
                                                    input: {color: '#fff'},
                                                    label: {color: '#fff'}
                                                  }}
                                                  {...params} />}
                  />
                  <DivButtons>
                    <Button 
                      bg="secondary" onClick={() => setshowDateLastVisitModal(!showDateLastVisitModal)}
                    >
                      Cancelar
                    </Button>
                    <Button 
                      bg="success" onClick={() => {handleDateLastVisitModal(); setshowDateLastVisitModal(!showDateLastVisitModal)}}
                    >
                      Confirmar
                    </Button>
                  </DivButtons>
              </Modal>
          )}
          <ContainerPaginate>
            <ReactPaginate
              previousLabel={<AiOutlineCaretLeft size={22} />}
              pageCount={pageCount}
              previousLinkClassName="previous_page"
              nextLabel={<AiOutlineCaretRight size={22} />}
              nextLinkClassName="next_page"
              activeClassName="active"
              onPageChange={data => setPage(data.selected * 5)}
            />
          </ContainerPaginate>
        </Container>
      )}
    </>
  );
}
