import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Cards({
  children,
  active,
  width,
  height,
  transparent,
  className,
}) {
  return (
    <Container
      className={`${className} ${active ? 'active' : ''}`}
      width={width}
      height={height}
      transparent={transparent}
    >
      {children}
    </Container>
  );
}

Cards.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  transparent: PropTypes.bool,
  className: PropTypes.string,
};

Cards.defaultProps = {
  active: false,
  width: '410px',
  height: null,
  transparent: false,
  className: '',
};
