import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import {
  // AiOutlineCalendar,
  // AiOutlineBell,
  // AiFillPlusCircle,
  AiOutlineUser,
} from 'react-icons/ai';
// import search from 'assets/search.svg';

import {
  // Search,
  Container,
  HeaderNav,
  DropDownMenu,
  SubPagesNav,
} from './styles';

import { signOut } from 'store/modules/auth/actions';

import routesInfo from 'routes/routesInfo';

import { api_nort } from 'services/api';
import { format } from 'date-fns';

// import Can from 'components/Can';

export default function Header() {
  const [showUserDropDown, setShowUserDropDown] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  const { name } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const [, choosenPath, choosenSubPath] = window.location.pathname.split('/');

  const pageInfo = routesInfo[choosenPath];
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  
  useEffect(() => {
    async function fetchData() {
      const { data } = await api_nort.get(`/Usuario/notificacao/${id}`);
      if(data !== ''){
        setMessage(data.msg === null ? "-" : data.msg + ' - Data de alteração: ' + format(new Date(data.created_at), 'dd/MM/yyyy'));
        setUrl(data.url === null ? "-" : data.url);
      }
    }
    fetchData();
  }, [id]);

  return (
    <>
      <Container>
        <div />
        {/* <Search>
        <img src={search} alt="Search icon" />
        <input type="text" placeholder="Procurar..." />
        </Search> */}

        <SubPagesNav>
          {pageInfo.subPages.map(subPage => (
            <Link
              className={choosenSubPath === subPage.path ? 'active' : ''}
              key={subPage.title}
              to={`/${pageInfo.path}/${subPage.path}`}
            >
              {subPage.title}
            </Link>
          ))}
        </SubPagesNav>
        {<HeaderNav>
          <li className="notification">
            <Link to={url || ''} style={{color: "red"}}>
              {message || ''}
            </Link>
          </li>
        </HeaderNav>}
        <HeaderNav>
          {/* <li>
          <AiOutlineCalendar type="calendar" />
        </li>
        */}
          <li className="name">{name}</li>
          <li className={showUserDropDown ? 'active' : ''}>
            <button
              type="button"
              className="user"
              onClick={() => setShowUserDropDown(!showUserDropDown)}
              onBlur={() => setTimeout(() => setShowUserDropDown(false), 250)}
            >
              <AiOutlineUser type="user" />
            </button>
            <DropDownMenu className={showUserDropDown && 'show'}>
              <button type="button" onClick={() => dispatch(signOut())}>
                Sair
              </button>
            </DropDownMenu>
          </li>
        </HeaderNav>
      </Container>
    </>
  );
}
