import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../InputPercent';

export default function PercentObjectInput({
  label,
  value,
  onChange,
  precision,
  prefix,
  suffix,
  decimalSeparator,
  thousandSeparator,
  extra,
  ...rest
}) {
  const [input, setInput] = useState(maskInput(Number(value).toFixed(precision)));

  function maskInput(cleanValue) {

    // GET DIGITS ARRAY
    let regex = /\d/g
    let digits = cleanValue.toString().match(regex) || ['0'];

    // ADD ZEROES TO DECIMAL
    while (digits.length <= precision) digits.unshift('0');

    // ADD DECIMAL SEPARATOR
    if (precision > 0) {
      digits.splice(digits.length - precision, 0, '.');
    }

    // clean up extraneous digits like leading zeros.
    digits = Number(digits.join(''))
      .toFixed(precision)
      .split('');

    let decimalpos = digits.length - precision - 1; // -1 needed to position the decimal separator before the digits.
    if (precision > 0) {
      // set the final decimal separator
      digits[decimalpos] = decimalSeparator;
    } else {
      // when precision is 0, there is no decimal separator.
      decimalpos = digits.length;
    }

    // add in any thousand separators
    for (let x = decimalpos - 3; x > 0; x -= 3) {
      digits.splice(x, 0, thousandSeparator);
    }

    // if we have a prefix or suffix, add them in.
    if (prefix.length > 0) {
      digits.unshift(prefix);
    }
    if (suffix.length > 0) {
      digits.push(suffix);
    }

    return digits.join('').trim();
  }

  function handleChange(e) {
    const result = maskInput(e.target.value);
    setInput(result);
    onChange(result, extra);
  }

  return (
    <div style={{width: '100px', margin: '0 auto'}}>
       {label && (
        <label>
          {label}
        </label>
      )}
      <Input
        style={{width: '100%', textAlign: 'center', color: '#000000', padding: 0}}
        type="text"
        value={input}
        onChange={handleChange}
        {...rest}
        />
    </div>
  );
}

PercentObjectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  precision: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  extra: PropTypes.object,
};

PercentObjectInput.defaultProps = {
  value: '',
  onChange: () => {},
  precision: 2,
  prefix: '',
  suffix: '',
  decimalSeparator: '.',
  thousandSeparator: '.',
};
