import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { MdOutlineMail } from 'react-icons/md';

import { toast } from 'react-toastify';
import Button from 'components/Button';
import LogoImg from 'assets/logo-login.png';

import { WrapperGray, 
        Wrapper, 
        ContainerImage,
        Container, 
        Form,
        Logo,
        HeaderGroup,
        BottomGroup,
        InputGroup } from './styles';
import api, { api_nort } from 'services/api';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await api_nort.post('/Usuario/recuperar-slim', {
        email,
        redirect_url: `${process.env.REACT_APP_CLIENT_URL ||
          'http://localhost:3000'}/reset_password`,
      });
      setLoading(false);
      setSended(!sended);
    } catch (err) {
      toast.error('Email não encontrado');
      setLoading(false);
    }
  }

  return (
    <WrapperGray>
      <Wrapper>
          {sended && (
            <>
            <Container>
              <Logo src={LogoImg} alt="Yuno On" className="imgLogo" />
              <div className="text-container">
                <h1>Esqueceu sua senha?</h1>
                <span>
                  Um link para redefinir sua senha foi enviado para {email}
                </span>
              </div>
              <div className="centered m-8">
                <Link to="/">Voltar ao Login</Link>
              </div>
            </Container>
            <ContainerImage>
              <HeaderGroup>
                <div className="watermark" />
                <h1>Planejamento Financeiro<br />
                para quem quer curtir o presente e desfrutar do futuro.
                </h1>
              </HeaderGroup>
              <BottomGroup>
                <h2>Viver é o melhor investimento!</h2>
              </BottomGroup>
            </ContainerImage>
          </>
          )}
          {!sended && (
            <>
              <Container>
                <Logo src={LogoImg} alt="Yuno On" className="imgLogo" />
                <div className="text-container">
                  <h1>Esqueceu sua senha?</h1>
                  <span>Digite aqui o e-mail que você cadastrou sua conta.</span>
                </div>
                <Form onSubmit={handleSubmit}>
                  <div className="control-group">
                    <label className="control-label" htmlFor="inputEmail">E-mail</label>
                    <InputGroup className="bordered">
                      <div className="button-lock">
                        <MdOutlineMail size={25} />
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="inputEmail"
                        placeholder="Digite seu e-mail"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <Button className="m-8 inter" type="submit" color="blueDarker" loading={loading}>
                    Enviar
                  </Button>
                  <div className="centered m-8">
                    <Link to="/">Voltar ao Login</Link>
                  </div>
                </Form>
              </Container>
              <ContainerImage>
                <HeaderGroup>
                  <div className="watermark" />
                  <h1>Planejamento Financeiro<br />
                  para quem quer curtir o presente e desfrutar do futuro.
                  </h1>
                </HeaderGroup>
                <BottomGroup>
                  <h2>Viver é o melhor investimento!</h2>
                </BottomGroup>
            </ContainerImage>
            </>
          )}
      </Wrapper>
    </WrapperGray>
  );
}
