import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import { SignIn, ForgotPassword, ResetPassoword } from 'pages/auth';

import { Home, Customers, Calendar, ClientsPfg, Reports, Strategy } from 'pages/dashboard';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/reset_password" component={ResetPassoword} />

      <Route path="/home" component={Home} isPrivate dashboard />
      <Route path="/clients" component={Customers} isPrivate dashboard />
      <Route path="/clientsPfg" component={ClientsPfg} isPrivate dashboard />

      <Route path="/calendar" component={Calendar} isPrivate dashboard />

      <Route path="/reports" exact component={Reports} isPrivate dashboard />

      <Route path="/strategy" exact component={Strategy} isPrivate dashboard />
    </Switch>
  );
}
