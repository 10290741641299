import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  &.loader-container {
    margin-top: 10vh;
    height: 300px;
  }
`;
