import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Container } from './styles';

import ListClasses from './ListClasses';

import Loading from 'components/Loading';

import api from 'services/api';
import ListPupils from './ListPupils';

export default function ClientsPfg() {
  const [classesPfg, setClassesPfg] = useState([]);
  const [selectedClass, setSelectedClass] = useState({});

  const [isLoad, setIsLoad] = useState(true);

  const { id } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function loadclassesPfg() {
      setIsLoad(true);
      const response = await api.get(`/planners/${id}/pfg_classes`, {
        params: { instructor: true },
      });

      if(response.status === 200) {
        const { data } = response;
        setClassesPfg(data.data);
      }
      setIsLoad(false);
    }
    loadclassesPfg();
  }, [id]);

  useEffect(() => {
    if (classesPfg.length === 0) return;

    setSelectedClass(classesPfg[0]);
  }, [classesPfg]);

  return (
    <>
      {isLoad ? (
        <Container className="loader-container">
          <Loading />
        </Container>
      ) : (
        <Container>
          {classesPfg?.length > 0 &&
              <ListClasses
                classesPfg={classesPfg}
                selectedClass={selectedClass}
                setSelectedClass={setSelectedClass}
              />
            }
            {selectedClass?.id &&
              <ListPupils key={selectedClass?.id} selectedClassId={selectedClass.id} />
            }
          
        </Container>
      )}
    </>
  );
}
