import styled from 'styled-components';

import { measures } from '../styles';

const {
  sidebarWidth,
  sidebarIconsSize,
  sidebarHomeIconSize,
  avatarSize,
} = measures;

export const Container = styled.aside`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 100vh;
  width: ${sidebarWidth}px;
  background: #ffffff;
  border: solid 1px #cccccc;
  overflow-y: auto;
`;

export const Avatar = styled.img`
  margin-top: 33px;
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  object-fit: contain;
  border: 4px solid ${props => props.theme.primary.default};
  border-radius: 50%;
`;

export const SideNav = styled.ul`
  list-style: none;
  li {
    display: flex;
    border-radius: 15px;
    width: ${sidebarIconsSize}px;
    height: ${sidebarIconsSize}px;
    transition: 500ms ease;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    svg {
      width: 65%;
      height: 65%;
      fill: #bae0ff;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      transition: 500ms ease;
    }

    &:hover {
      svg {
        fill: ${props => props.theme.info.default};
      }

      background: #e6f4ff;
    }

    &.active {
      background: #e6f4ff;
      svg {
        fill: ${props => props.theme.info.default};
      }
    }

    & + li {
      margin-top: 40px;
    }
    cursor: pointer;
  }
`;

export const HomeIcon = styled.div`
  display: flex;
  border-radius: 17px;
  background: none;
  height: ${sidebarHomeIconSize}px;
  width: ${sidebarHomeIconSize}px;
  margin-bottom: 25px;
  transition: 500ms ease;

  a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 65%;
    height: 65%;
    fill: #bae0ff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: 500ms ease;
  }

  &:hover {
    background-color: #e6f4ff;
    svg {
      fill: ${props => props.theme.info.default};
    }
  }

  &.active {
    background-color: #e6f4ff;
    svg {
      fill: ${props => props.theme.info.default};
    }
  }
`;
