import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';
import Button from 'components/Button';

export default function ListClasses({
  classesPfg,
  selectedClass,
  setSelectedClass,
}) {
  function isActiveClass(classPfg) {
    return classPfg.name === selectedClass.name;
  }

  return (
    <Container>
      {classesPfg.map(classPfg => (
        <Button
          key={classPfg.id}
          className={isActiveClass(classPfg) && 'active'}
          onClick={() => setSelectedClass(classPfg)}
        >
          {classPfg.name}
        </Button>
      ))}
    </Container>
  );
}

ListClasses.propTypes = {
  classesPfg: PropTypes.arrayOf().isRequired,
  selectedClass: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  setSelectedClass: PropTypes.func.isRequired,
};
