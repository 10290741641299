import styled from 'styled-components';

import { colors } from 'styles/global';

export const ModalStyled = styled.div`
  div.head {
    display: flex;
    background: #1b202a;
    height: 65px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding: 60px 20px;

    h3 {
      margin: 0 !important;
      color: #fff;
    }

    svg {
      margin-right: 10px;
      font-size: 30px;
    }
  }

  div.body {
    display: flex;
    flex-direction: column;
    background: #1b202a;
    padding: 25px 50px;
    height: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    small {
      color: ${colors.white.default};
    }

    div.css-1uccc91-singleValue {
      color: ${colors.white.default};
    }

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: red;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }

    div {
      background: #1c1f2c;
      border: unset;
      label {
        color: #fff;
        fill: #515a67;
      }
    }
    input {
      color: #fff;

      background: #1c1f2c;
      :hover {
        border-bottom: 1px solid #515a67;
      }
      ::placeholder {
        color: #515a67;
      }
    }
  }

  -webkit-box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
`;

export const DivBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;


