import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import getObjectDiff from 'utils/getObjectDiff';

import PercentInput from 'components/masks/PercentObjectInput';

import Loading from 'components/Loading';

import ReactTooltip from 'react-tooltip';

import Select from 'components/Input/Dropdown';

import Button from 'components/Button'

import { api_nort } from 'services/api';

import Table from 'components/Table/DefaultPercent';

import {
  Container,
  Title,
  Toolbar,
  TableContainer,
  HeaderTitle
} from './styles';

import debounce from 'utils/debounce';

const headTable = [
  'Investimento',
  'ELP Recomendada',
  'ELP Definida'
];


export default function Strategy() {

  const { id } = useSelector(state => state.user.profile);

  const [ clientList, setClientList ] = useState([{label: "Carregando lista...", value: null}]);

  const [filter, setFilter] = useState(clientList[0]);

  const [isLoading, setIsLoading] = useState(false);

  // Planners da api
  const [tableList, setTableList] = useState([]);

  const [ dadosPerfilCliente, setDadosPerfilCliente ] = useState(null)

  const [ dataList, setDataList ] = useState([]);

  const [ buttonDisabled, setButtonDisabled ] = useState(true);

  const [ initialDataList, setInitialDataList ] = useState([]);

  const [ percentageTable, setPercentageTable ] = useState(headTable);

  const [ totalPercent, setTotalPercent ] = useState({
    percentual_recomendado: 0,
    percentual_definido: 0
  });


  const customStyles = {
    control: (base, state) => ({
      ...base,
      // border: '0 !important',
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        // border: '0 !important',
      },
      background: '#f2f2f2',
      color: '#212529',
      // match with the menu
      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      background: '#f2f2f2',
      color: '#212529',
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: '#f2f2f2',
      color: '#212529',
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color: '#212529',
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
  };

  const startLoading = () => {

    setIsLoading(true);

    setTableList([]);
  }

  useEffect(() => {
    const { percentual_definido } = totalPercent;

    setButtonDisabled(percentual_definido !== 100);

    const difs = getObjectDiff(initialDataList, dataList)
                .map((item) => {
                  const _index = parseInt(item)
                  const itemDif = dataList[_index]
                  return  itemDif
                })

    if(difs.length <= 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(percentual_definido !== 100);
    }
  // eslint-disable-next-line
  }, [totalPercent])

  useEffect(() => {
    if(dataList.length > 0){

      const percents = dataList.reduce((counter, obj) => {
        counter.percentual_recomendado += obj.percentual_recomendado;
        counter.percentual_definido += obj.percentual_definido;
        return counter;
      }, {percentual_recomendado: 0, percentual_definido: 0});

      
      const titlesWithPercent = headTable.filter((i, index) => index > 0 && index < 3).map((item, index) => {
        const percent = index === 0 ? percents.percentual_recomendado : percents.percentual_definido;
        const strPercent = `(${percent}%)`;

        const title = (
          <HeaderTitle key={`headertitle-${item}`}>
            <span>{item}</span>
            <span className={percent !== 100 ? 'danger': 'percent'}>{strPercent}</span>
          </HeaderTitle>
        )
        return title;
      })

      setTotalPercent(percents);

      // Add Column "Investimento"
      titlesWithPercent.unshift(<HeaderTitle key="title"><span>{headTable[0]}</span></HeaderTitle>)

      setPercentageTable(titlesWithPercent);

      const list = dataList.map((item) => {

        return [
          `${item.nome_investimento}`,
          `${item.percentual_recomendado}%`,
          <PercentInput
              key={`${item.id_estrategia}`}
              extra={item}
              precision={1}
              value={item.percentual_definido}
              onChange={updateValues}
            />
        ]
      });

      setTableList(list);
    }
    // eslint-disable-next-line
  }, [dataList])

  // Load initial clients
  useEffect(() => {
    async function listCLients() {
      //const response = await api_nort.get('/Usuario/get/client');
      const response = await api_nort.get(`Usuario/${id}/clienteplannersuitability`);

      var resultsConverted = response.data.map((cliente) => {
        return {
          label: cliente.nome,
          value: cliente.id
        }
      })

      resultsConverted.unshift({
        label: "Selecione o cliente",
        value: 0
      })

      setFilter(resultsConverted[0])
      setClientList(resultsConverted);
    }
    listCLients();
    // eslint-disable-next-line
  }, []);

  const fetchClientsData = async() => {
      if(filter.value === 0 || filter.value === null) return;
      startLoading();

      api_nort.get(`estrategia/${filter.value}/elpdefinida`)
      .then((response) => {
        if(response.status !== 200) {
          toast.error('Nenhum retorno foi encontrado para esse cliente.');
          setIsLoading(false);
          setTableList([]);
          setInitialDataList([]);
          setDadosPerfilCliente(null);
          return;
        } else {
          const { perfil, contrato, estrategia } = response.data;

          setDataList(estrategia);
  
          setInitialDataList(estrategia);
  
          setDadosPerfilCliente({ perfil, contrato })
        
          setIsLoading(false);
        }
        // Handle response
      })
      .catch((reason) => {
        toast.error('Ocorreu um erro.');
        setIsLoading(false);
        setTableList([]);
        setInitialDataList([]);
        setDadosPerfilCliente(null);
      })
  }

  useEffect(() => {
    debounce(fetchClientsData, 600);
    // eslint-disable-next-line
  }, [filter])

  const updateValues = (newValue, oldState) => {

    debounce(() => {

      const { id_estrategia } = oldState;

      const findState = dataList.map(item => {
        if(item.id_estrategia === id_estrategia) {
          const itemReplaced = {
            ...item,
            percentual_definido: parseFloat(newValue)
          }

          return itemReplaced
        }
        return item;
      })

      setDataList(findState)
    }, 800)
  }

  const atualizaDados = () => {

    const difs = getObjectDiff(initialDataList, dataList)
                .map((item) => {
                  const _index = parseInt(item)
                  const itemDif = dataList[_index]
                  return  itemDif
                })

    if(difs.length > 0) {
      setButtonDisabled(true);
      const dataToSend = dataList.map(item => {
        return {
          id_estrategia: item.id_estrategia,
          percentual_definido: item.percentual_definido
        }
      })

      const { percentual_definido } = totalPercent;

      if(percentual_definido > 100) {
        toast.error("A soma da ELP Definida não pode ser maior do que 100");
        return;
      } else if(percentual_definido < 100) {
        toast.error("A soma da ELP Definida não pode ser menor do que 100");
        return;
      }
      startLoading();
      async function updateDados() {
        
        const response = await api_nort.patch('/estrategia/elpdefinida', dataToSend);

        if(response.data.sucesso) {
          toast.success('Dados atualizados!');
          startLoading();
          fetchClientsData();
        }
      }
      updateDados();

    } else {
      toast.error('Não existem dados alterados.');
    }
  }

  return (
    <>
      <Container>
        <Toolbar>
          <Select
              value={filter}
              options={clientList}
              onChange={selected => setFilter(selected)}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,

                colors: {
                  neutral50: '#fff',
                  primary: '#fff',
                },
              })}
            />
          <Title>
            Perfil: {dadosPerfilCliente !== null ? dadosPerfilCliente.perfil : "--"}
          </Title>
          <Title>
            Contrato: {dadosPerfilCliente !== null ? dadosPerfilCliente.contrato : "--"}
          </Title>
          <Button type="button" color="info" onClick={atualizaDados} disabled={buttonDisabled}>
            Atualizar dados
          </Button>
        </Toolbar>
        { isLoading &&
          <Loading style={{height: '300px', marginTop: '100px'}} />
        }
        { tableList.length > 0 &&
          <TableContainer>
            <Table head={percentageTable} body={tableList} linear={true} centered={[false, true, true]} headClass={"no-padding-bottom"}/>
          </TableContainer>
        }
      </Container>
      <ReactTooltip />
    </>
  );
}
