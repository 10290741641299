const home = {
  title: 'Painel de controle',
  path: 'home',
  subPages: [],
};

const clients = {
  title: 'Clientes',
  path: 'clients',
  subPages: [],
};

const reports = {
  title: 'Relatórios',
  path: 'reports',
  subPages: [],
};

const strategy = {
  title: 'Estratégia',
  path: 'strategy',
  subPages: [],
};

const calendar = {
  title: 'Calendário',
  path: 'calendar',
  subPages: [],
};

const clientsPfg = {
  title: 'Clientes PFg`s',
  path: 'clientsPfg',
  subPages: [],
};

const routesInfo = {
  clients,
  home,
  calendar,
  clientsPfg,
  reports,
  strategy
};

export default routesInfo;
