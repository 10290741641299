import React from 'react';
import PropTypes from 'prop-types';

import { Table, Thead, Tbody } from './styles';

export default function DefaultPercent({ head, body, colorList, linear, centered, headClass }) {

  const getColor = (index) => {
    if(colorList.length <= 1) {
      return ''
    } else {
      return colorList[index]
    }
  }

  const getCenter = (index) => {
    let _center = false;
    if(centered.length > 0) {
      _center = centered[index];
    }

    return _center;
  }
  return (
    <Table>
      <Thead className={headClass}>
        <tr>
          {head.map((content, index) => {
              let _class = getCenter(index) ? 'text-center': '';
              return (
                <th className={_class} key={`content-${index}`}>{content}</th>
              )
            })
          }
        </tr>
      </Thead>
      <Tbody>
        {body.map((row, index) => (
          <tr key={Math.random()} className={getColor(index)}>
            {row.map((column, index) => {
              let _class = getCenter(index) ? 'text-center': '';
              return (
                <td className={linear ? `linear ${_class}`: _class} key={Math.random()}>{column}</td>
              )
            })}
          </tr>
        ))}
      </Tbody>
    </Table>
  );
}

DefaultPercent.propTypes = {
  head: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
  body: PropTypes.arrayOf(PropTypes.array),
  colorList: PropTypes.arrayOf(PropTypes.string),
  linear: PropTypes.bool,
  centered: PropTypes.arrayOf(PropTypes.bool),
  headClass: PropTypes.string,
};

DefaultPercent.defaultProps = {
  head: ['head', 'head', 'head'],
  body: [['linha', 'linha', 'linha']],
  colorList: [''],
  linear: false,
  centered: [false]
};
