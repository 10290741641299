import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import { add, startOfDay } from 'date-fns';

import { toast } from 'react-toastify';
import { ModalContent, DivButton, Form, DivGroup } from '../styles';

import Modal from 'components/Modal';

import Input from 'components/Input';
import Select from 'components/Input/Dropdown';
import Button from 'components/Button';
import api from 'services/api';

export default function UpdateModal({
  setShowUpdateModal,
  selectedEvent,
  setEvents,
}) {
  // Dados do formulário
  const id = selectedEvent?._id;

  const [name, setName] = useState(selectedEvent?.name);
  const [description, setDescription] = useState(selectedEvent?.description);

  const eventStartData = new Date(selectedEvent?.date_start);

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [where, setWhere] = useState(selectedEvent?.where);
  const [how, setHow] = useState(selectedEvent?.how);
  const [who, setWho] = useState(selectedEvent?.who);

  const [done, setDone] = useState(selectedEvent?.done);

  // Caso o evento não seja uma reunião, meeting será null
  const [meeting, setMeeting] = useState(() => {
    if (selectedEvent.meeting_type) {
      return {
        value: selectedEvent.meeting_type.name,
        label: selectedEvent.meeting_type.name,
        id: selectedEvent.meeting_type.id,
      };
    }
    return null;
  });

  // Dados vindos da api
  const [meetingsType, setMeetingsType] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get('/meeting_types');

      setMeetingsType(
        data.map(meet => ({
          value: meet.name,
          label: meet.name,
          id: meet.id,
        }))
      );
    }
    fetchData();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (meeting) {
        const [startHour, startMinute] = start.split(':');

        const [endHour, endMinute] = end.split(':');

        if (startHour > endHour) {
          return toast.warn('O horário inicial deve ser menor que o final');
        }
        const dayStart = startOfDay(new Date(eventStartData));

        const eventStart = add(dayStart, {
          hours: startHour,
          minutes: startMinute,
        });

        const eventEnd = add(dayStart, {
          hours: endHour,
          minutes: endMinute,
        });

        const { data } = await api.put(`/meetings/${id}`, {
          meeting_type_id: meeting.id,
          description,
          start: eventStart,
          end: eventEnd,
        });
        return setEvents(event => {
          if (event.id === data.id) return;
          return event;
        });
      }
      return toast.success('Editado com sucesso');
    } catch (err) {
      return toast.error('Falha ao editar evento');
    }
  }

  return (
    <Modal title="Editar evento" color="info" icon={null}>
      <ModalContent>
        {!meeting && (
          <DivButton>
            <div className={done ? 'checked' : 'unchecked'}>
              <button onClick={() => setDone(!done)}>
                {done ? (
                  <>
                    <AiOutlineCheck size={32} />
                    <span>Desconcluir</span>
                  </>
                ) : (
                  <>
                    <AiOutlineClose size={32} />
                    <span>Concluir</span>
                  </>
                )}
              </button>
            </div>
          </DivButton>
        )}
        <Form onSubmit={handleSubmit}>
          {!meeting && (
            <Input
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          )}

          {meeting && (
            <>
              <Select
                label="Tipo da reunião"
                options={meetingsType}
                defaultValue={meeting}
                onChange={select => setMeeting(select)}
              />
              <DivGroup>
                <Input
                  type="time"
                  label="Início"
                  width="45%"
                  value={start}
                  onChange={e => setStart(e.target.value)}
                />
                <Input
                  type="time"
                  label="Fim"
                  width="45%"
                  value={end}
                  onChange={e => setEnd(e.target.value)}
                />
              </DivGroup>
            </>
          )}

          <Input
            label="Descrição"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          {!meeting && (
            <>
              <Input
                label="Onde?"
                value={where}
                onChange={e => setWhere(e.target.value)}
              />
              <Input
                label="Como?"
                value={how}
                onChange={e => setHow(e.target.value)}
              />
              <Input
                label="Quem?"
                value={who}
                onChange={e => setWho(e.target.value)}
              />
            </>
          )}

          <div className="select-buttons">
            <Button color="grafit" onClick={() => setShowUpdateModal(false)}>
              Cancelar
            </Button>
            <Button color="info" type="submit">
              Confirmar
            </Button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
}

UpdateModal.propTypes = {
  setShowUpdateModal: PropTypes.func.isRequired,
  selectedEvent: PropTypes.shape({
    meeting_type: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
  setEvents: PropTypes.func.isRequired,
};
