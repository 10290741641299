import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';

import ReactPaginate from 'react-paginate';

import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

import { format } from 'date-fns';
import { Container, ContainerPaginate } from './styles';

import Table from 'components/Tables/Default';
import Dropdown from 'components/Button/Dropdown';

import api from 'services/api';
import userName from 'utils/userName';

export default function ListPupils({ selectedClassId }) {
  const [pupilsClass, setPupilsClass] = useState([]);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const token = useSelector(state => state.auth.token);

  const loggedUser = useSelector(state => state.user.profile);

  const head = ['Nome', 'ID', 'Data de criação', ''];

  useEffect(() => {
    async function loadPupilsClass() {
      if(selectedClassId === undefined) {
        toast.error('Erro ao tentar consultar dados, não há valor para realizar a consulta.');
        return;
      } 
      const { data } = await api.get(
        `/pfg_classes/${selectedClassId}/clients`,
        {
          params: {
            page,
          },
        }
      );

      setPageCount(data.lastPage);
      setPage(data.page);

      setPupilsClass(
        data.data.map(customer => [
          userName(customer),
          customer?.id,
          format(new Date(customer?.created_at), 'dd/MM/yyyy'),
          <Dropdown>
            <button
              onMouseDown={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL ||
                    'http://localhost:3000'}/external_acess?token=${token}&user_id=${
                    customer.id
                  }&external_user_id=${loggedUser.id}`
                );
              }}
            >
              Acessar a Yuno On
            </button>
          </Dropdown>,
        ])
      );
    }
    loadPupilsClass();
  }, [selectedClassId, loggedUser.id, token, page]);

  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  return (
    <Container>
      <Table head={head} body={pupilsClass} />

      <ContainerPaginate>
        <ReactPaginate
          previousLabel={<AiOutlineCaretLeft size={22} />}
          pageCount={pageCount}
          previousLinkClassName="previous_page"
          nextLabel={<AiOutlineCaretRight size={22} />}
          nextLinkClassName="next_page"
          activeClassName="active"
          onPageChange={handlePageClick}
        />
      </ContainerPaginate>
    </Container>
  );
}

ListPupils.propTypes = {
  selectedClassId: PropTypes.number.isRequired,
};
