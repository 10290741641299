import React from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './styles';

export default function Button({ color, isCustomColor, children, ...rest }) {
  return (
    <ButtonStyled color={color} {...rest} isCustomColor={isCustomColor}>
      {children}
    </ButtonStyled>
  );
}

Button.propTypes = {
  color: PropTypes.string,
  isCustomColor: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.string,
    PropTypes.node,
  ]),
};

Button.defaultProps = {
  children: 'Botão',
  isCustomColor: false,
  color: 'info',
};
