import React from 'react';
import PropTypes from 'prop-types';

import { Table, Thead, Tbody } from './styles';

export default function DefaultTable({ head, body, colorList }) {

  const getColor = (index) => {
    if(colorList.length <= 1) {
      return ''
    } else {
      return colorList[index]
    }
  }
  return (
    <Table>
      <Thead>
        <tr>
          {head.map(content => (
            <th key={content}>{content}</th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {body.map((row, index) => (
          <tr key={Math.random()} className={getColor(index)}>
            {row.map(column => (
              <td key={Math.random()}>{column}</td>
            ))}
          </tr>
        ))}
      </Tbody>
    </Table>
  );
}

DefaultTable.propTypes = {
  head: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.arrayOf(PropTypes.array),
  colorList: PropTypes.arrayOf(PropTypes.string),
};

DefaultTable.defaultProps = {
  head: ['head', 'head', 'head'],
  body: [['linha', 'linha', 'linha']],
  colorList: ['']
};
