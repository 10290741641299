import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3333',
});

export const api_nort = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_NEW_API || 'http://localhost:55555/api',
  // baseURL: 'https://realteste.eastus.cloudapp.azure.com/TESTE_RealeasyAPI/api',
});

export default api;
