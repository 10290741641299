import styled, { css } from 'styled-components';

import { colors } from 'styles/global';

export const ButtonStyled = styled.button`
  ${props =>
    props.isCustomColor
      ? css`
          background: ${props.color} !important;
        `
      : css`
          background: ${props.theme[props.color].default} !important;
        `}
  color: #fff;
  font-weight: bold;
  padding: 0 18px;
  height: 48px;
  border-radius: 8px;
  transition: 500ms ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => colors[props.color].disabled} !important;
  }

  &:hover:not(:disabled) {
    ${props =>
      props.isCustomColor
        ? css`
            background: ${props.color} !important;
          `
        : css`
            background: ${props.theme[props.color].hover} !important;
          `}
  }
`;
