import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import DashboardLayout from 'pages/_layouts/Dashboard';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  dashboard = false,
  path,
  ...rest
}) {

  const { signed } = useSelector(state => state.auth);

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (dashboard) {
          return (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isPrivate: PropTypes.bool,
  dashboard: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  dashboard: false,
};
